import * as React from 'react'
import { StatusPage } from '../components/statusPage'

const NotFoundPage = () => {
  return (
    <StatusPage code={'404'}>
      Sorry, we can&apos;t find that page. You&apos;ll find lots to explore on
      the home page.
    </StatusPage>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
